
<template>
<div class="user-login">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-5">
        <div class="card mb-0">
          <div class="card-body p-4 p-md-5 p-lg-6">
            <div class="mb-4 text-center">
              <h1 class="h3">Sign in</h1>
            </div>
            <user-login-form @takeAction="takeAction($event)" />
            <p class="mb-0 text-center">
              Don't have an account? <br class="d-sm-none" > <router-link to="/register" class="text-success font-weight-bold" >Sign up now</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserLoginForm from '@/components/forms/UserLoginForm.vue'

export default {
  components:{
    UserLoginForm
  },
  methods: {
    takeAction(data){
      this.$store.commit('SET_AUTH_TOKEN', data);
      if(this.$route.query.intended){
        location.replace(this.$route.query.intended)
      }else{
        location.replace('/')
      }
    },
   },
  }
</script>
